.section-container{
    max-width: 95%;
    width: 100%;
    margin: auto;
    @media (min-width: 640px) {
        max-width: $c-sm-width;
    }
    @media (min-width: 768px) {
        max-width: $c-md-width;
    }
    @media (min-width: 1024px) {
        max-width: $c-lg-width;
    }
    @media (min-width: 1280px) {
        max-width: $c-xl-width;
    }
    @media (min-width: 1536px) {
        max-width: $c-2xl-width;
    }
}
