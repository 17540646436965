// Fonts
@use "sass:math";

@import url('https://fonts.googleapis.com/css2?family=Roboto');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=Rubik');

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

// Variables
@import 'variables';

@import 'mixins';
@import 'icons';
@import 'animations';

@import 'layouts/container';

// plugins
@import '~swiper/swiper-bundle.css';
@import '~animate.css';
@import '~nouislider/dist/nouislider.css';
@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';
@import '~sweetalert2/src/sweetalert2.scss';
@import "~@fancyapps/ui/dist/fancybox.css";


// Bootstrap
// @import '~bootstrap/scss/bootstrap';

// container

.container{
    margin: auto;
}

div[data-tippy-root]{
    cursor: pointer !important;
}

.tippy-box{
    &[data-theme~="profile-box"] {
        .tippy-content{
            padding: 0;
            border:1px solid #777;
            background-color: white;
        }
        .tippy-arrow{
            color: white;
        }
    }
}

.tippy-tooltip{
    padding: 0;
    background-color: white;
    &.profile-box-theme {
        .tippy-content{
            padding: 0;
            background-color: white;
        }
        .tippy-arrow{
            color: white;
            background: white;
            transform: rotate(45deg);
        }
    }
}

.swiper-button-next,
.swiper-button-prev{
    text-align: center;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    background-color: $green;
    color: white;
    &::after{
        content: '';
    }
}

.swiper-button-next.outside,
.swiper-button-prev.outside{
    width: 20px;
    height: 20px;
    border:rgb(189, 189, 189) solid 1px;
}

.swiper-button-next.outside{
    background-color: rgb(255, 255, 255);
    color: rgb(189, 189, 189);
    right: -10px;
}

.swiper-button-prev.outside{
    background-color: rgb(255, 255, 255);
    color: rgb(189, 189, 189);
    left: -10px;
}

span.cb-icon{
    display: block;
    width: 30px;
    height: 30px;
    @media (min-width: 768px) {
        width: 40px;
        height: 40px;
    }
    &.cert{
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('/img/marker/icons/cert.svg');
    }
    &.lot{
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('/img/marker/icons/lot_size.svg');
    }
    &.building{
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('/img/marker/icons/building_size.svg');
    }
    &.shower{
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('/img/marker/icons/shower.svg');
    }
    &.parking{
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('/img/marker/icons/parking.svg');
    }
    &.bed{
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('/img/marker/icons/bed.svg');
    }
}

// map

// marker
// Class Identifier: house apart land villa shop stall office hotel std warehouse other
.label-map{
    padding-left: 28px;
    &.wanted{
        padding: 10px;
        width: 30px;
        height: 30px;
        border-radius: 20px;

        &::before{
            width: 100%;
        }
    }
    &::before {
        content: ' ';
        display: block;
        width: 28px;
        padding: 1px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background-position: center;
        background-repeat: no-repeat;
    }
    &.house{
        &::before{
            background-image: url('/img/marker/icons/house.svg');
            background-size: 18px auto;
        }
    }
    &.apart{
        &::before{
            background-image: url('/img/marker/icons/apart.svg');
            background-size: 11px auto;
        }
    }
    &.factory{
        &::before{
            background-image: url('/img/marker/icons/factory.svg');
            background-size: 16px auto;
        }
    }
    &.land{
        &::before{
            background-image: url('/img/marker/icons/land.svg');
            background-size: 20px auto;
        }
    }
    &.villa{
        &::before{
            background-image: url('/img/marker/icons/villa.svg');
            background-size: 20px auto;
        }
    }
    &.shop{
        &::before{
            background-image: url('/img/marker/icons/shop.svg');
            background-size: 11px auto;
        }
    }
    &.stall{
        &::before{
            background-image: url('/img/marker/icons/stall.svg');
            background-size: 20px auto;
        }
    }
    &.office{
        &::before{
            background-image: url('/img/marker/icons/office.svg');
            background-size: 20px auto;
        }
    }
    &.hotel{
        &::before{
            background-image: url('/img/marker/icons/hotel.svg');
            background-size: 8px auto;
        }
    }
    &.std{
        &::before{
            background-image: url('/img/marker/icons/std.svg');
            background-size: 20px auto;
        }
    }
    &.warehouse{
        &::before{
            background-image: url('/img/marker/icons/warehouse.svg');
            background-size: 20px auto;
        }
    }
    &.other{
        &::before{
            background-image: url('/img/marker/icons/other.svg');
            background-size: 20px auto;
        }
    }
}


// info popup

.info-img-prop-wrapper{
    width: 100px;
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: math.div(1, 1) * 100%;
    }

    >.image {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.card-props{
    .icon-fav{
        background-image: url('/img/faav.png');
        &.is-fav{
            background-image: url('/img/faav1.png');
        }
    }
}

.marker-wrapper-project {
    height: 35px;
    position: relative;
    .label-text {
        width: auto;
        min-width: 60px;
        height: 75%;
        position: relative;
        padding-left: 35px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        border-radius: 15px;

        .label-image {
            height: 35px;
            width: 35px;
            background-color: inherit;
            padding: 2.5px;
            position: absolute;
            top: -13%;
            left: -5px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                margin: auto;
                width: 90%;
                height: 90%;
                object-fit: cover;
                object-position: center;
                border-radius: 100%;
            }
        }

        p {
            position: relative;
            z-index: 5;
        }
        .arrow {
            z-index: 0;
            position: absolute;
            top: 15px;
            left: 0;
            right: 0;
            margin: auto;
            background: inherit;
            width: 15px;
            height: 15px;
            transform: rotatez(-45deg);
        }
    }

}

.unit-image-wrap {
    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 230px;
    }

    >.unit-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

// icons
.icon::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

.pass-eye::before {
    font-family: "Font Awesome 5 Free";
    content: "\f06e";
}

.pass-eye.slash::before {
    font-family: "Font Awesome 5 Free";
    content: "\f070";
}

label.required::after {
    margin-left: 3px;
    content: '*';
    color: #D0103A;
}

.sharethis-inline-share-buttons {
    z-index:1 !important;
}