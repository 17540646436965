.icon{
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    display: inline-block;
    background-size: cover;
    background-position: center;
    margin-right: 10px;

    &.overview{
        background-image: url('/img/icons/overview.png');
    }
}

span.form-helper{
    background-image: url('/img/icons/question.png');
}

// filters
.residential-type{
    [data-type].label {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    [data-type='1'].label{
        background-image: url("/img/filter/1_house_outline.png");
    }
    [data-type='2'].label{
        background-image: url("/img/filter/2_apartment_outline.png");
    }
    [data-type='3'].label{
        background-image: url("/img/filter/13_land_plot_outline.png");
    }
    [data-type='4'].label{
        background-image: url("/img/filter/4_villa_outline.png");
    }
}

.commercial-type{
    [data-type].label {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    [data-type='5'].label{
        background-image: url("/img/filter/5_shophouse_outline.png");
    }
    [data-type='6'].label{
        background-image: url("/img/filter/6_stall_outline.png");
    }
    [data-type='7'].label{
        background-image: url("/img/filter/13_land_plot_outline.png");
    }
    [data-type='8'].label{
        background-image: url("/img/filter/8_office_outline.png");
    }
    [data-type='9'].label{
        background-image: url("/img/filter/9_hotel_outline.png");
    }
    [data-type='15'].label{
        background-image: url("/img/filter/10_stand alone_outline.png");
    }
}

.industrial-items{
     [data-type].label {
         background-size: cover;
         background-repeat: no-repeat;
         background-position: center;
     }

     [data-type='11'].label {
         background-image: url("/img/filter/11_factory_outline.png");
     }
     [data-type='12'].label {
         background-image: url("/img/filter/12_warehouse_outline.png");
     }
     [data-type='13'].label {
         background-image: url("/img/filter/13_land_plot_outline.png");
     }
     [data-type='14'].label {
         background-image: url("/img/filter/14_others_outline.png");
     }
}
