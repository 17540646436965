// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$green: #006400;
$red: #BF0603;
$orange: #ED9B40;
$yellow: #ffed4a;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$c-sm-width: 640px;
$c-md-width: 740px;
$c-lg-width: 1000px;
$c-xl-width: 1180px;
$c-2xl-width: 1180px;
